<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Receipts</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import { currencyFilter } from '@/utils/currency'

// const wrappedBaseActions = actionsWrapper(BaseActionsRequiresWrapper, 'Payments')

const columns = [
  // {
  //   _t_id: '6cf09066',
  //   prop: '/',
  //   text: 'Actions',
  //   component: wrappedBaseActions,
  //   sort: false,
  //   filter: false,
  //   detail: false,
  //   type: 'actions',
  // },
  {
    _t_id: 'aaf5c93a',
    prop: 'receiptId',
    text: 'Receipt ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'receiptId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'aaf5cc28',
    prop: 'accountId',
    text: 'Account ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'accountId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'aaf5cda4',
    prop: 'name',
    text: 'Company Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'name',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'aaf5cee4',
    prop: 'total',
    text: 'Total',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'total',
    defaultSort: true,
    computedText: (item) => currencyFilter(item),
    detail: false,
  },
  {
    _t_id: 'aaf5d4e8',
    prop: 'createdOn',
    text: 'Created On',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'createdOn',
    defaultSort: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
  {
    _t_id: 'aaf5d614',
    prop: 'createdBy',
    text: 'Created By',
    sort: true,
    type: 'text',
    filter: true,
    filterType: 'contains',
    sortProp: 'createdBy/lastName',
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Receipts',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'receiptId',
        tableId: 'receipts_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Receipts',
      },
    }
  },
}
</script>
